import React from 'react';

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="brand">METHOD-X</div>
      <button 
        className="buy-now" 
        onClick={() => window.location.href='https://uniswap.org'}>
        Buy METH Soon
      </button>
    </div>
  );
};

export default Navbar;
