import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <a href="https://t.me/Method_X_Chat" target="_blank" rel="noopener noreferrer">
        **Join the METHOD-X Community on Telegram**
      </a>
      <div className="social-links">
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
      </div>
    </div>
  );
};

export default Footer;
