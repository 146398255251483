import React from 'react';
import './App.scss';
import methImage from './meth.png';
import Navbar from './components/Navbar';
import FloatingBox from './components/FloatingBox';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="main-content">
        <img src={methImage} alt="Method-X" className="main-image" />
        <div className="floating-box-container">
          <FloatingBox 
            items={[
              "Hold METHOD-X and earn BRETT on every transaction!",
              "Huge Global Community",
              "Partnerships with other projects for a stronger future!",
            ]} 
          />
          <FloatingBox 
            items={[
              "Community-driven Development",
              "Liquidity Locked",
              "Fixed Supply of 420 Billion Tokens",
              "2% Tax",
              <span>LBP Event: <a href="https://shorturl.at/2rjG4">https://shorturl.at/2rjG4</a> Be a part of the Reward revolution on BASE</span>
            ]} 
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
